import clsx from 'clsx'
import { Link } from 'gatsby'
import React from 'react'

interface Heading {
  id: string
  value: string
  depth: number
}

export default function TableOfContents({ headings }: { headings: Heading[] }) {
  console.log(headings)
  const headingsCount = headings.length
  return (
    <>
      {headings.length > 0 && (
        <>
          <h2 id="on-this-page-title" className="font-display text-sm font-medium text-slate-900 dark:text-white">
            On this page
          </h2>
          <ol role="list" className="mt-4 space-y-3 text-sm">
            {headings
              .filter((h) => h.depth === 2)
              .map((section) => (
                <li key={section.id}>
                  <h3>
                    <Link
                      href={`#${section.id}`}
                      className={clsx(
                        'font-normal text-slate-500 hover:text-slate-700 dark:text-slate-400 dark:hover:text-slate-300',
                      )}
                    >
                      {section.value}
                    </Link>
                  </h3>
                  {headings.filter((h) => h.depth === 3).length > 0 && headingsCount <= 8 ? (
                    <ol role="list" className="mt-2 space-y-3 pl-5 text-slate-500 dark:text-slate-400">
                      {headings
                        .filter((h) => h.depth === 3)
                        .map((subSection) => (
                          <li key={subSection.id}>
                            <Link
                              href={`#${subSection.id}`}
                              className={'hover:text-slate-600 dark:hover:text-slate-300'}
                            >
                              {subSection.value}
                            </Link>
                          </li>
                        ))}
                    </ol>
                  ) : null}
                </li>
              ))}
          </ol>
        </>
      )}
    </>
  )
}
