import { Link } from 'gatsby'
import React from 'react'

interface PageConfig {
  href?: string
  title: string
}

export default function PageSwitcher({ previousPage, nextPage }: { previousPage?: PageConfig; nextPage?: PageConfig }) {
  return (
    <div className="flex pt-5 items-center justify-between">
      {previousPage && (
        <div>
          <Link to={previousPage?.href}>
            <div className="-mx-3 px-3 py-3 hover:bg-slate-100 dark:hover:bg-slate-700 rounded-lg cursor-pointer">
              <div className="font-display text-sm font-medium text-slate-900 dark:text-white">Previous</div>
              <div className="mt-1 text-base font-semibold text-slate-500 dark:text-slate-400">
                <span aria-hidden="true">&larr;</span> {previousPage.title}
              </div>
            </div>
          </Link>
        </div>
      )}
      {nextPage && (
        <div className="text-right">
          <Link to={nextPage?.href}>
            <div className="-mx-3 px-3 py-3 hover:bg-slate-100 dark:hover:bg-slate-700 rounded-lg cursor-pointer">
              <div className="font-display text-sm font-medium text-slate-900 dark:text-white">Next</div>
              <div className="mt-1 text-base font-semibold text-slate-500 dark:text-slate-400">
                {nextPage.title} <span aria-hidden="true">&rarr;</span>
              </div>
            </div>
          </Link>
        </div>
      )}
    </div>
  )
}
